<template>
  <div class="relative text-sm">
    <div class="absolute inset-y-0 left-0 flex items-center px-4">
      <i class="fas fa-volume-up text-primary-green"></i>
    </div>
    <input
      :id="id"
      :placeholder="placeholder"
      class="block w-full px-10 pr-14 py-3 border rounded-lg shadow focus:outline-none focus:ring focus:ring-primary-orange focus:ring-opacity-50 transition ease-in duration-150"
    />
    <button
      class="absolute inset-y-0 right-0 px-3 flex-shrink-0 flex-items-center bg-primary-green text-white border border-l-0 rounded-lg"
    >
      <svg
        class="h-6 w-6 text-white"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <circle cx="12" cy="12" r="9" stroke="currentColor" stroke-width="2" />
        <circle cx="12" cy="12" r="6" fill="currentColor" />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: ["id", "placeholder"],
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style></style>
