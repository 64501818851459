<template>
  <div class="text-xl space-x-2">
    <a :class="classes" href="#" target="_blank"
      ><i class="fab fa-facebook-f"></i
    ></a>
    <a :class="classes" href="#" target="_blank"
      ><i class="fab fa-twitter"></i
    ></a>
    <a :class="classes" href="#" target="_blank"
      ><i class="fab fa-linkedin-in"></i
    ></a>
    <a :class="classes" href="#" target="_blank"
      ><i class="fab fa-whatsapp"></i
    ></a>
    <a :class="classes" href="#" target="_blank"
      ><i class="fab fa-instagram"></i
    ></a>
  </div>
</template>

<script>
export default {
  props: {
    flip: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: () => ({}),
  created() {},
  computed: {
    classes() {
      return (
        "inline-flex items-center justify-center h-10 w-10 rounded-full " +
        (this.flip
          ? "bg-white text-primary-orange"
          : "bg-primary-orange text-white")
      );
    },
  },
  methods: {},
};
</script>

<style></style>
